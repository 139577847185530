import { graphql } from "gatsby"
import React, { FC } from "react"
import { Props } from "./types"
import IntegrationSubPage from "src/components/IntegrationSubPage"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  },
}) => (
  <IntegrationSubPage
    html={html}
    title={title}
    {...texts}
    buttonLink="https://www.flow.help/hc/en-us/articles/360033975074-Create-an-account-with-Google"
    getLink="https://myaccount.google.com/intro?utm_source=flow&utm_medium=integrations"
  />
)

export const pageQuery = graphql`
  query($ymlPath: String!, $mdPath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $mdPath }) {
      html
      frontmatter {
        title
      }
    }

    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        back
        getText
        buttonText
      }
    }
  }
`

export default Template
